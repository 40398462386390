<template>
  <el-card shadow="never" header="资源使用记录">
    <div class="m-b-15 flex h vc">
      <div style="width: 380px">
        <el-date-picker
          v-model="selectDate"
          type="daterange"
          range-separator="To"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          :shortcuts="shortcuts"
        />
      </div>
      <el-select placeholder="上报类型" v-model="selectType" style="width: 100px" class="m-l-15" clearable>
        <el-option :value="1" label="支付补报"></el-option>
        <el-option :value="2" label="支付上报"></el-option>
        <el-option :value="3" label="注册上报"></el-option>
      </el-select>
      <el-button type="primary" style="margin-left: 15px" @click="handleSearch">查询</el-button>
    </div>
  </el-card>
  <PaginationView :current-page="currentPage" @current-change="handlePageChange"
                  :total="selectedCount"></PaginationView>

  <el-table :data="list" stripe border>
    <el-table-column type="index" width="60"></el-table-column>
    <el-table-column prop="user.appChannel" label="渠道包" width="210" align="left">
      <template #default="{row}">
        <div>{{ channelFormat(row) }}</div>
        <div>{{ adFormat(row) }}</div>
      </template>
    </el-table-column>
    <el-table-column prop="user.name" label="用户">
      <template #default="{row}">
        <router-link v-if="row.userInfo" :to="{name:'manage_user_detail', params:{id:row.userInfo.userId}}"
                     target="_blank">
          <div style="white-space: nowrap">{{ row.userInfo.name ? row.userInfo.name: '未知'}}</div>
        </router-link>
        <div v-else>未知用户</div>
      </template>
    </el-table-column>
    <el-table-column label="客户端">
      <template #default="{row}">
        <div v-if="row.userInfo">{{ row.userInfo.appName }}</div>
        <div v-else>{{ row.appId }}</div>
        <div v-if="row.userInfo">{{ row.userInfo.deviceBrand }}</div>
      </template>
    </el-table-column>
    <el-table-column prop="createdAt" label="记账统计" width="110" align="center">
      <template #default="{row}">
        <div>手动记账：{{ row.commonBookingCount || 0}}</div>
        <div>AI记账：{{ row.aiBookingCount || 0}}</div>
        <div>定时记账：{{ row.regularBookingCount || 0}}</div>
        <div>资产账户：{{ row.assetCount || 0}}</div>
      </template>
    </el-table-column>
    <el-table-column prop="registerAt" label="注册上报" width="110" align="left">
      <template #default="{row}">
        <div v-if="row.registerAt||(row.activeAt && row.adId)">{{
          dateFormat(row.registerAt?row.registerAt:row.activeAt)}}
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="payAt" label="支付上报" width="210" align="left">
      <template #default="{row}">
        <div v-if="row.payAmount" class="price">金额：{{ row.payAmount / 100 }}</div>
        <div v-if="row.payAt">{{ dateFormat(row.payAt) }}</div>
      </template>
    </el-table-column>
    <el-table-column prop="repairAt" label="支付补报" width="210" align="left">
      <template #default="{row}">
        <div v-if="row.repairAmount" class="price">金额：{{ row.repairAmount / 100 }}</div>
        <div v-if="row.repairAt">{{ dateFormat(row.repairAt) }}</div>
      </template>
    </el-table-column>
    <el-table-column prop="createdAt" label="注册时间" width="110" align="left">
      <template #default="{row}">
        <div v-if="row.userInfo">{{ row.userInfo.created }}</div>
      </template>
    </el-table-column>
  </el-table>
  <PaginationView :current-page="currentPage" @current-change="handlePageChange"
                  :total="selectedCount"></PaginationView>
</template>

<script>
  import {attributionUserReportsApi, attributionApi} from '@/api/admin.js'
  import moment from 'moment'

  export default {
    name: 'UserManager',
    data() {
      return {
        list: [],
        selectDate: null,
        selectStatus: null,
        searchKWs: null,
        shortcuts: [
          {
            text: '今天',
            value: () => {
              const today = moment().format('YYYY-MM-DD')
              return [today, today]
            }
          },
          {
            text: '昨天',
            value: () => {
              const tomorrow = moment().subtract(1, 'day').format('YYYY-MM-DD')
              return [tomorrow, tomorrow]
            }
          }
        ],
        payChannelMap: {
          1: '微信支付',
          2: '微信支付',
          3: '支付宝',
          4: '苹果支付',
          5: '抖音支付'
        },
        showForm: false,
        form: {},
        selectedCount: 0,
        currentPage: 1,
        selectType: 1,
        searchAppChannel: null,
        searchOsName: null,
        selectAppId: null,
        osNames: [
          'ios',
          'android'
        ],
        adSettings: [],
        channelSettings: []
      }
    },
    created() {
      const today = moment().format('YYYY-MM-DD')
      this.selectDate = [today, today]
      this.init()
    },
    methods: {
      async init() {
        const res = await attributionUserReportsApi.list({
          index: this.currentPage - 1,
          limit: 20,
          page: this.currentPage,
          start: this.selectDate ? this.selectDate[0] : null,
          end: this.selectDate ? this.selectDate[1] : null,
          selectType: this.selectType,
        })
        this.list = res.data.data.list
        this.selectedCount = res.data.data.count

        const res3 = await attributionApi.adSettings()
        this.adSettings = res3.data.data.list
        const res4 = await attributionApi.channels()
        this.channelSettings = res4.data.data.list
      },
      handlePageChange(page) {
        this.currentPage = page
        this.init()
      },
      handleSearch() {
        this.currentPage = 1
        this.init()
      },
      dateFormat(time) {
        return moment(time).format('YYYY-MM-DD HH:mm:ss')
      },
      adFormat(row) {
        if (row.adId) {
          const found = this.adSettings.find(adSetting => adSetting.adId === row.adId)
          if (found) {
            return found.name
          }
        }
      },
      channelFormat(row) {
        if (row.appChannel) {
          const found = this.channelSettings.find(adSetting => adSetting.appChannel === row.appChannel)
          if (found) {
            return found.name
          }
        }
      },
      payChannelFormat(item) {
        if (item.channel === 5) {
          if (item.way === 1) {
            return '微信'
          } else if (item.way === 2) {
            return '支付宝'
          } else if (item.way === 10) {
            return '抖音支付'
          } else {
            if (item.way) {
              return item.way
            }
          }
        }

        return this.payChannelMap[item.channel]
      },
    }
  }
</script>

<style scoped lang="less">
  .tongji-container {
    margin-top: 15px;
    background-color: #fff;

    .tongji-item-container {
      .label {
        margin-bottom: 10px;
      }

      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      font-size: 18px;
    }
  }
</style>
