<template>
  <el-card shadow="never" header="激活码管理">
    <div class="flex m-b-15">
      <div class="f1">共计：{{ list.length }} 个</div>
      <el-button type="primary" @click="handleAdd">添加</el-button>
    </div>
    <el-table :data="list">
      <el-table-column label="channelId" prop="channelId" width="100"></el-table-column>
      <el-table-column label="渠道名称" prop="title" width="300"></el-table-column>
      <el-table-column label="类型" prop="type" width="300">
        <template #default="{row}">
          {{typeFormat(row)}}
        </template>
      </el-table-column>
      <el-table-column label="数量" prop="count"></el-table-column>
      <el-table-column label="已使用" prop="usedCount"></el-table-column>
      <el-table-column label="状态" prop="status">
        <template #default="{row}">
          {{statusFormat(row)}}
        </template>
      </el-table-column>
      <el-table-column width="200">
        <template #default="{row}">
          <el-button @click="toDetail(row)" size="small" type="primary">详情</el-button>
          <el-button @click="handleEdit(row)" size="small">修改</el-button>
          <el-button @click="handleRemove(row)" type="danger" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <el-dialog title="添加激活码" v-model="showForm">
    <el-form :model="form">
      <el-form-item label="渠道名称" :label-width="100">
        <el-input v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="数量" :label-width="100">
        <el-input v-model="form.count"></el-input>
      </el-form-item>
      <el-form-item label="类型" :label-width="100">
        <el-select v-model="form.type">
          <el-option :label="item.title" :value="item.type" v-for="item in typeArr"
                     :key="item.type"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态" :label-width="100">
        <el-select v-model="form.status">
          <el-option :label="item.label" :value="item.val" v-for="item in statusArr"
                     :key="item.val"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showForm = false">取消</el-button>
        <el-button type="primary" @click="handleSubmit">提交</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
  import {redeemChannelApi} from '@/api/fenxiao'
  import {ElMessageBox} from 'element-plus'

  export default {
    name: 'SettingListScreen',
    data() {
      return {
        showForm: false,
        form: null,
        list: [],
        typeArr: [
          {
            type: 1,
            title: '激活码-月卡会员',
          },
          {
            type: 2,
            title: '激活码-季卡会员',
          },
          {
            type: 3,
            title: '激活码-年卡会员',
          },
          {
            type: 4,
            title: '激活码-永久会员',
          }

        ],
        statusArr: [
          {
            val: 0,
            label: '不可用'
          },
          {
            val: 1,
            label: '可用'
          }
        ]
      }
    },
    watch: {
      '$route.params'(newVal) {
        console.log('newVal', newVal)
        if (newVal) {
          this.init()
        }
      }
    },
    async created() {
      this.init()
    },
    methods: {
      statusFormat(row) {
        return this.statusArr.find(item => item.val === row.status).label
      },
      typeFormat(row) {
        return this.typeArr.find(item => item.type === row.status).title
      },
      async init() {
        const res = await redeemChannelApi.list({
          no_page: 1
        })
        this.list = res.data.data.list
      },
      toDetail(d) {
        this.$router.push({
          name: 'manage_fenxiao_redeem_code_list',
          params: {
            id: d._id
          }
        })
      },
      handleAdd() {
        this.showForm = 'add'
        this.form = {
          key: null,
          value: null
        }
      },
      handleEdit(d) {
        this.showForm = 'edit'
        this.form = JSON.parse(JSON.stringify(d))
      },
      async handleRemove(d) {
        await ElMessageBox.alert('确认删除', '删除', {
          confirmButtonText: '确认',
          callback: async (action) => {
            if (action === 'confirm') {
              await redeemChannelApi.remove(d._id)
              await this.init()
            }
          }
        })
      },
      async handleSubmit() {
        if (this.showForm === 'edit') {
          await redeemChannelApi.update(this.form._id, {data: this.form})
        } else {
          await redeemChannelApi.create(this.form)
        }
        this.showForm = null
        await this.init()
      }
    }
  }
</script>

<style scoped lang="less">
  .cover {
    width: 200px;
    height: 100px;
  }
</style>
